import axios from 'axios';
import get from 'lodash/get';
import errorLogger from 'shared/utils/errorLogger';
import { normalize } from 'normalizr';
import productsSchema from 'shared/schemas/products';
import { asyncNames, createActions } from '../utils';
import notify from '../notify';

export const names = asyncNames('FETCH_PRODUCTS');
export const products = createActions(names);

const getParams = params => {
  const { category, limit, ...rest } = params;
  const opts = {};
  if (category != null) {
    opts.category = category;
  }
  if (limit != null) {
    opts.max_per_page = limit;
  }
  return { ...opts, ...rest };
};

const fetchProducts =
  ({ params = {}, clear, cancelToken }) =>
  async dispatch => {
    await dispatch(products.request({ params, clear }));

    try {
      if (cancelToken) {
        cancelToken.current?.cancel();
        // eslint-disable-next-line no-param-reassign
        cancelToken.current = axios.CancelToken.source();
      }
      const response = await axios(
        AppRouting.generate('api_search_products', getParams(params)),
        {
          cancelToken: cancelToken?.current?.token,
        },
      );

      const data = normalize(response.data, productsSchema);

      await dispatch(products.success({ params, data }));
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }
      errorLogger(error);
      const message = get(
        error,
        'response.data.errors.global',
        i18next.t('products|||fetchFailure'),
      );

      dispatch(notify({ message }));
      dispatch(products.failure({ params, error }));
    }
  };

export default fetchProducts;
